.section2{
  text-align:center;
  width: 100vw;

}


.about-container{

margin-top:5em ;
width: 100%;
}

.about{
  position:relative;
  font-size: 1.2em;
  justify-content: center; 
  font-family: 'Orbitron', sans-serif;
  font-weight:500;
  margin: 2em;


}









 



          .ab-seven{
         
       margin-top: 10vh;
            display:flex;
            flex-direction: column;
            align-items: center;
            font-size: 15px;
          
          }

        

          .resumebutton{
            margin-top: -2vh;

            font-size: 2.5vh;
            padding: 10px;
          }       
          
            
         

     