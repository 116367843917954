.techs{
display: inline-flex;
justify-content: space-evenly;
flex-wrap: wrap;
margin-top: 10vh;

}

.tech-title{
  font-size: 1.4vh;
  font-weight: 300;
  background: #411e58a8;
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 8px; 
  font-family: 'Orbitron', sans-serif;
  margin-top: 3vh;
  
}

.tech{
  margin: 1.5rem;
 
}

.icon{
  width: 7vh;
}





.tech:hover{
  transition: all 200ms ease-in-out;
  transform: scale(1.7);
 
  
}

.tech:not(:hover){
  transition: all 200ms ease-in-out;
  
}

.tech-title:hover{
  background-color: white;
  color: #411e58;

}

