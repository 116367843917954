.modal-dialog{
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 999;
  top: 10vh;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

.modal-content{
  padding: 2px;
  justify-content: space-around; 
  position: relative; 
  display: flex;
  background: #49a09d4d;
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
 
  

 
}

.modaltext{
  margin-bottom: 3vh;
  padding: 50px;
  font-weight: 100;
  font-family: 'Orbitron', sans-serif;
  font-size: 0.9rem;
  text-align: justify;
}

.closebutton{
  
  margin-bottom: 15vh;
 
}


video{

  width: 35vh;
  margin-top: 5vh;
  background: #ffffffa8;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 3px solid rgba(250, 247, 247, 0.797);
  object-fit: cover;

}
[poster]{
  height: 20vh;
}


.modaltitle{
  position: relative;
  top: 15vh;
  font-size: 2rem;
  margin-bottom: 10vh;
  margin-top: -10vh;

}

.icon-projects{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-items: center;  
  margin-top: -5vh;
  column-gap: 50px;
  
  
}


.icon-title{
  font-size: 1.5vh;
}

svg{
  margin: 0 2vh 0 2vh;
}




.moreprojbutton{
  width: 130px;
}

.project-anim1{
  transition: all 500ms ease-in-out;
  transition-delay: 0ms;
}

.project-anim1.outview {          
  opacity: 0;
}

.project-anim2{
  transition: all 500ms ease-in-out;
  transition-delay:200ms;
}

.project-anim2.outview {          
  opacity: 0;
}

.project-anim3{
  transition: all 500ms ease-in-out;
  transition-delay: 400ms;
}

.project-anim3.outview {          
  opacity: 0;
}