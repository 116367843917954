.nav-bar {
  width: 4rem;
  height: 100vh;
  position: absolute;
  top: -3vh;
  z-index: 999;
  min-height: 500px;
  background: transparent;
  background-size: 400% 400%;
  transition: all 2000ms ease-in-out;
  transition-delay: 8000ms;
  opacity: 0.7;
  


  ul {
    position: relative;
 top: 8vh;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 4vh 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #ffffff;
        }
      }
    }
  }


  .nav-bar.outview {
   transform: translateX(-50px);
   opacity: 0;
  }




