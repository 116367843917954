.section3{
        text-align:center;
        justify-content: center;         
        width: 100vw;
            
}

ul {
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;

  li {
    .tech-icon {
      padding: 20px;
      display: block;
      font-size: 70px;
      line-height: 10px;
      color: #ffffff;
    }

     
  }
}



