
.section5{
text-align:center;

   
}



h1{
  font-size: 7vh;
}

form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10vh;
  margin-bottom:5vh;
  font-size: 16px
 
};

label{
  font-family: 'Orbitron', sans-serif;
}

textarea, input{
  font-family: 'Orbitron', sans-serif;
  background: #49a09d4d;
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 300px;
  height: 5vh;
  margin: 3vh;
 
  color: white;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 3px;

 
}

textarea:focus, input:focus{
  background-color: #602c82be;

}

textarea{
  height: 10vh;
}






.form-text{
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: 5vh;
  width: 70vw;
  font-size: 1rem;
  padding: 20px; 
  letter-spacing: 0.1rem;
line-height: 1.5;

}








ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;

  li {
    a {
      padding: 20px;
      display: block;
      font-size: 25px;
      line-height: 10px;
      color: #ffffff;
    }

     
  }
}





.text-anim-cont{
  transition: all 500ms ease-in-out;
transition-delay: 0ms;
}

.text-anim-cont.outview { 
  opacity: 0;
}

.icon-anim-cont{
  transition: all 500ms ease-in-out;
transition-delay: 200ms;
}

.icon-anim-cont.outview { 
  opacity: 0;
}

.form-anim-cont{
  transition: all 500ms ease-in-out;
transition-delay: 400ms;
}

.form-anim-cont.outview { 
  opacity: 0;
}