html {
  font-family: 'Orbitron', sans-serif;
  color: #ffffffe8;
  height: 100vh;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  
}



.bg {
  position: fixed;  
  width: 100vw;
  height: 1000vh;
  // background: rgba(0, 0, 0, 0.344) url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-color: #00000071;
  background-repeat:repeat;
  opacity: 1;
  visibility: visible;
  overflow:-moz-hidden-unscrollable;
  
}

.bg{
  transition: all 1000ms ease-in-out;
transition-delay: 4000ms;
}

.bg.outview { 
  opacity: 0;
}

// @keyframes bg-animation {
//     0% { transform: translate(0,0) }
//     10% { transform: translate(-5vw,-5vw) }
//     20% { transform: translate(-10vw,5vw) }
//     30% { transform: translate(5vw,-10vw) }
//     40% { transform: translate(-5vw,15vw) }
//     50% { transform: translate(-10vw,5vw) }
//     60% { transform: translate(15vw,0) }
//     70% { transform: translate(0,10vw) }
//     80% { transform: translate(-15vw,0) }
//     90% { transform: translate(10vw,5vw) }
//     100% { transform: translate(5vw,0) }
// }




.title{
font-size: 5vh;
font-family: 'Orbitron', sans-serif;
font-weight:900;
text-shadow:  10px 10px 10px rgba(54, 47, 47, 0.353);
position: relative;
white-space: nowrap;
transition: all 1500ms ease;
transition-delay: 0ms;
opacity: 0.90;
letter-spacing: 1.5vh;


}


.title.outview {
  opacity:0;
  
}


.mainbutton{
  position: relative;
  top:3rem;
  left: 0rem;
  z-index: 99;
  color: white;
  background: #411e58a8;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  font-size: 2vh;
  padding: 1vh 5vh 1vh 5vh;
  white-space: nowrap;
 
 

}

.mainbutton:hover{
  color: #411e58;
  transition: all 200ms ease-in-out;
  background-color:white;
  transform: scale(1.2);
}

.mainbutton:not(:hover){
  transition: all 200ms ease-in-out;
}




canvas {

	-webkit-animation: canvas 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: canvas 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes canvas {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes canvas {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


a{
  padding: 0;  
}

a:hover{
  color: #49a09d;
}

svg{
  transform: scale(1.3);
  transition: all 200ms ease-in-out;
  
}

svg:hover{
  transform: scale(1.9);
}


.top-page{
  width: 3px;
  height: 3px;
  position: absolute;
  top: -120vh;
  scroll-behavior: smooth;
}



