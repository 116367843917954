



/* #root {
  overflow: hidden;
} */

body {
  /* overflow: hidden; */
  margin: 0;
}


body,
html,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
 
}




