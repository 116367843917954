.section6{
  text-align:center;
  width: 100vw;


}
  

.section6.outview {
  background-color: transparent; 
}

.title-footer{

  top:20vh;

}


.footerbutton{
  position: relative;
margin-top: -50vh;

}

a {
  color: white;
  text-decoration: none;
  padding: 5px;
}



.text-muted{
  margin-top: 7vh;
  padding-bottom: 20px;
  white-space: nowrap;
  font-size: 3vh;
}

.text-center{
  position: relative;
  top: 10vh;
  
}

.lines{
  position: absolute;
  top: 20vh;
  margin-bottom: -40vh;
}



.fa-angles-up {
  font-size: 2rem;
  margin-top: 3vh;
  scroll-behavior: smooth;

}

.card-body{
  padding-top: 50vh;
  background-image: linear-gradient( #01010100, #000000);
  height: 100vh;

  
}