.section4{
text-align:center;
width: 100vw;
display: flex;
flex-direction: column;

   
}


.projects{
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 10vh;

}



.project{ 

 width: 35vh;
 height: 22vh;
 text-align:center;
 margin: 5vh;
 overflow: hidden;
z-index: 9;
background: rgba( 73, 160, 157, 0.3 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
// blur performance
-webkit-backface-visibility: hidden;
-webkit-perspective: 1000;
-webkit-transform: translate3d(0,0,0);
-webkit-transform: translateZ(0);
backface-visibility: hidden;
perspective: 1000;
transform: translate3d(0,0,0);
transform: translateZ(0);
}

.project.outview {          
  opacity: 0;
}


.titmodal{
  font-size: 3vh;
	margin-top: 3vh;
  font-weight: 300;
  font-family: 'Orbitron', sans-serif;

}









