.section1 { 

text-align:center;
width: 100vw;
height: 100vh;
transition: all 1000ms ease-in-out;
transition-delay: 500ms;

}


.hello {
transition: all 1400ms ease-in-out;
transition-delay: 1000ms;
font-size: 1.2rem;
text-shadow:  5px 5px 5px rgba(54, 47, 47, 0.353);
font-family: 'Orbitron', sans-serif;
font-weight:200;
margin: 0;
position: relative;
top:-1vh;

}



.hello.outview {
  opacity: 0;
}

.ric {
transition: all 1400ms ease-in-out;
transition-delay: 2500ms;
margin: 0;
font-size: 6vh;


}


.ric.outview {
  opacity: 0;
}


.beg {
  transition: all 1400ms ease-in-out;
  transition-delay: 4000ms;
  font-family: 'Orbitron', sans-serif;
  font-weight:200;
  font-size: 1.2rem;
  text-shadow:  5px 5px 5px rgba(54, 47, 47, 0.353);
  margin-top: 1vh;

  }



  .beg.outview {
   
    opacity: 0; 
   
  }


  .fron {
    
    
    font-family: 'Oswald', sans-serif; 
    
  
    }





.scroll-anim {
  position: absolute;
  top: -65vh;
  width: 100vw;
  transition: all 2500ms ease-in-out;
  transition-delay: 5000ms;
  opacity: 0.3;
}

.scroll-anim.outview {
  opacity: 0;  
}





